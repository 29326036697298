export default {
    dashboardPageView: "Dashboard - Page View",

    accountRemovePageView: "Account Remove - Page View",
    accountRemoveSubmitClick: "Account Remove - Submit Click",

    activityListPageView: "Activity List - Page View",
    activityListNewRegisterClick: "Activity List - New Register Click",
    activityListShowRegisterClick: "Activity List - Show Register Click",
    activityListEditRegisterClick: "Activity List - Edit Register Click",
    activityListCloseRegisterClick: "Activity List - Close Register Click",
    activityListRemoveRegisterClick: "Activity List - Remove Register Click",
    activityListRemoveRegisterConfirmClick: "Activity List - Remove Register Confirm Click",
    activityListRemoveRegisterCancelClick: "Activity List - Remove Register Cancel Click",
    activityListRemoveRegisterSuccess: "Activity List - Remove Register Success",

    activityFormFieldFieldAddItemClick: "Activity Form - Field Field Add Item Click",
    activityFormFieldFieldEditItemClick: "Activity Form - Field Field Edit Item Click",
    activityFormFieldFieldRemoveItemClick: "Activity Form - Field Field Remove Item Click",
    activityFormFieldFieldSaveItemClick: "Activity Form - Field Field Save Item Click",
    activityFormPatrimonyAddItemClick: "Activity Form - Patrimony Add Item Click",
    activityFormPatrimonyEditItemClick: "Activity Form - Patrimony Edit Item Click",
    activityFormPatrimonyRemoveItemClick: "Activity Form - Patrimony Remove Item Click",
    activityFormPatrimonySaveItemClick: "Activity Form - Patrimony Save Item Click",
    activityFormInputAddItemClick: "Activity Form - Input Add Item Click",
    activityFormInputEditItemClick: "Activity Form - Input Edit Item Click",
    activityFormInputRemoveItemClick: "Activity Form - Input Remove Item Click",
    activityFormInputSaveItemClick: "Activity Form - Input Save Item Click",

    assistantOpenChatClick: "Assistant - Open Chat Click",
    assistantCloseChatClick: "Assistant - Close Chat Click",
    assistantSendMessageClick: "Assistant - Send Message Click",


    cropListPageView: "Crop List - Page View",
    cropListRemoveRegisterClick: "Crop List - Remove Register Click",
    cropListRemoveRegisterConfirmClick: "Crop List - Remove Register Confirm Click",
    cropListRemoveRegisterCancelClick: "Crop List - Remove Register Cancel Click",
    cropListRemoveRegisterSuccess: "Crop List - Remove Register Success",
    cropListRemoveRegisterError: "Crop List - Remove Register Error",

    dashboardChartsExpensesOnChartClick: "Dashboard - Expenses - On Chart Click",
    dashboardChartsExpensesOnLegendClick: "Dashboard - Expenses - On Legend Click",

    dashboardChartsYearlyWeatherOnChartClick: "Dashboard - Yearly Weather - On Chart Click",
    dashboardChartsYearlyWeatherOnLegendClick: "Dashboard - Yearly Weather - On Legend Click",

    fieldListPageView: "Field List - Page View",

    financeListPageView: "Finance List - Page View",
    financeListFilterApply: "Finance List - Filter Apply",
    financeListChangedTabClick: "Finance List - Changed Tab Click",
    financeListNewRegisterClick: "Finance List - New Register Click",

    financeListEditRegisterClick: "Finance List - Edit Register Click",
    financeListShowRegisterClick: "Finance List - Show Register Click",
    financeListCloseRegisterClick: "Finance List - Close Register Click",
    financeListRemoveRegisterClick: "Finance List - Remove Register Click",
    financeListRemoveRegisterConfirmClick: "Finance List - Remove Register Confirm Click",
    financeListRemoveRegisterCancelClick: "Finance List - Remove Register Cancel Click",
    financeListRemoveRegisterSuccess: "Finance List - Remove Register Success",

    financeFormInputAddItemClick: "Finance Form - Input Add Item Click",
    financeFormInputEditItemClick: "Finance Form - Input Edit Item Click",
    financeFormInputRemoveItemClick: "Finance Form - Input Remove Item Click",
    financeFormInputSaveItemClick: "Finance Form - Input Save Item Click",

    financeFormServiceAddItemClick: "Finance Form - Service Add Item Click",
    financeFormServiceEditItemClick: "Finance Form - Service Edit Item Click",
    financeFormServiceRemoveItemClick: "Finance Form - Service Remove Item Click",
    financeFormServiceSaveItemClick: "Finance Form - Service Save Item Click",

    financeFormCropAddItemClick: "Finance Form - Crop Add Item Click",
    financeFormCropEditItemClick: "Finance Form - Crop Edit Item Click",
    financeFormCropRemoveItemClick: "Finance Form - Crop Remove Item Click",
    financeFormCropSaveItemClick: "Finance Form - Crop Save Item Click",

    financeFormInstallmentAddItemClick: "Finance Form - Installment Add Item Click",
    financeFormInstallmentEditItemClick: "Finance Form - Installment Edit Item Click",
    financeFormInstallmentRemoveItemClick: "Finance Form - Installment Remove Item Click",
    financeFormInstallmentSaveItemClick: "Finance Form - Installment Save Item Click",

    financeFormTransportGroupedByCropAddItemClick: "Finance Form - Transport Grouped By Crop Add Item Click",
    financeFormTransportGroupedByCropEditItemClick: "Finance Form - Transport Grouped By Crop Edit Item Click",
    financeFormTransportGroupedByCropSaveItemClick: "Finance Form - Transport Grouped By Crop Save Item Click",
    financeFormTransportGroupedByCropRemoveItemClick: "Finance Form - Transport Grouped By Crop Remove Item Click",

    financeFormFileOpenDrawerClick: "Finance Form File -  Open Drawer Click",
    financeFormFileCloseDrawerClick: "Finance Form File - Close Drawer Click",
    financeFormFileUploadSuccess: "Finance Form File - Upload Success",
    
    subscriptionFormPageView: "Subscription Form - Page View",
    subscriptionFormPlanSelectorButtonSubmitClick: "Subscription Form - Plan Selector Submit Click",
    subscriptionFormRegisterButtonSubmitSuccess: "Subscription Form - Register Button Submit Success",
    subscriptionFormRegisterButtonSubmitError: "Subscription Form - Register Button Submit Error",
    subscriptionFormRegisterButtonSubmitClick: "Subscription Form - Register Button Submit Click",
    subscriptionFormPaymentButtonSubmitError: "Subscription Form - Payment Button Submit Error",
    subscriptionFormPaymentButtonSubmitClick: "Subscription Form - Payment Button Submit Click",
    subscriptionFormPaymentButtonSubmitSuccess: "Subscription Form - Payment Button Submit Success",
    subscriptionFormPaymentCardButtonSubmitClick: "Subscription Form - Payment Card Button Submit Click",
    subscriptionFormPaymentCardButtonSubmitSuccess: "Subscription Form - Payment Card Button Submit Success",
    subscriptionFormPaymentCardButtonSubmitError: "Subscription Form - Payment Card Button Submit Error",
    subscriptionFormSubmitClick: "Subscription Form - Submit Click",
    subscriptionFormSubmitSuccess: "Subscription Form - Submit Success",
    subscriptionFormSubmitError: "Subscription Form - Submit Error",


    subscriptionShowPageView: "Subscription Show - Page View",
    subscriptionShowCancelClick: "Subscription Show - Cancel Click",
    subscriptionShowCancelSuccess: "Subscription Show - Cancel Success",
    subscriptionShowCancelError: "Subscription Show - Cancel Error",
    subscriptionShowRenewClick: "Subscription Show - Renew Click",
}