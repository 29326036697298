import { useEffect, useState } from 'react';

import { IAAssistantMessage } from '@types';
import {
    useGetOwnerAssistantConversation,
    useCreateOwnerConversation,
    useCreateOwnerConversationMessage,
} from 'services/owner';


import {
    trackingEvents,
} from '@constants';
import { useTracking } from 'contexts';

import { Chat } from './components';

import { Core } from './Assistant.style';

import type { AssistantProps } from './Assistant.type';

export const Assistant = ({ uuid, setUuid }: AssistantProps) => {
    const [messages, setMessages] = useState<Partial<IAAssistantMessage>[]>([]);

    const { trackEvent } = useTracking();

    const {
        mutateAsync: createConversation,
        isLoading: isCreatingConversation,
    } = useCreateOwnerConversation();
    const { mutateAsync: createMessage, isLoading: isCreatingMessage } =
        useCreateOwnerConversationMessage(uuid || '');

    const { data: conversation, isLoading: isLoadingConversation } = useGetOwnerAssistantConversation(uuid);

    const onInitConversation = async (content: string) => {
        const responseConversation = await createConversation();

        setMessages([
            ...messages,
            {
                conversationUuid: responseConversation.data?.uuid || '',
                content,
                author: 'user',
            },
        ]);

        if (responseConversation.success) {
            setUuid(responseConversation.data?.uuid || '');

            const responseMessage = await createMessage({
                conversationUuid: responseConversation.data?.uuid || '',
                content,
                author: 'user',
            });

            if (responseMessage.success) {
                setMessages([
                    ...messages,
                    {
                        conversationUuid: responseConversation.data?.uuid || '',
                        content,
                        author: 'user',
                    },
                    {
                        ...responseMessage.data,
                        author: 'assistant',
                    },
                ]);
            }
        }
    };

    const onContinueConversation = async (content: string) => {
        setMessages([
            ...messages,
            {
                conversationUuid: uuid || '',
                content,
                author: 'user',
                createdAt: new Date().toDateString(),
            },
        ]);

        const responseMessage = await createMessage({
            conversationUuid: uuid || '',
            content,
            author: 'user',
        });

        if (responseMessage.success && responseMessage.data) {
            setMessages([
                ...messages,
                {
                    conversationUuid: uuid || '',
                    content,
                    author: 'user',
                    createdAt: new Date().toDateString(),
                },
                responseMessage.data,
            ]);
        }
    };

    const onSendMessage = async (message: string) => {
        if (uuid) {
            onContinueConversation(message);
        } else {
            onInitConversation(message);
        }

        trackEvent(trackingEvents.assistantSendMessageClick, {
            message,
            newConversation: !uuid,
        });
    };


    useEffect(() => {
        if (conversation?.data) {
            setMessages(conversation.data.messages);
        } else {
            setMessages([]);
        }
    }, [conversation])

    return (
        <Core>
            <Chat
                messages={messages as IAAssistantMessage[]}
                onSendMessage={onSendMessage}
                isLoading={isCreatingConversation || isCreatingMessage || isLoadingConversation}
            />
        </Core>
    );
};
