import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';

import {
    trackingEvents,
} from '@constants';

import {  useTracking } from 'contexts';

import { Assistant, Drawer, DrawerFormHeader } from 'components';
import { ContentWrap, PanelOwnerWrap } from './styles';

import Header from './Header';
import Sidebar from './Sidebar';

const PanelOwner = () => {
    const { t } = useTranslation();

    const [isOpenResponsiveSidebar, setIsOpenResponsiveSidebar] =
        useState(false);
    const [isOpenResponsiveMenu, setIsOpenResponsiveMenu] = useState(false);

    const [isOpenChat, setIsOpenChat] = useState(false);

    const { trackEvent } = useTracking();

    const onHandleOpenChat = () => {
        setIsOpenChat(true);
        trackEvent(trackingEvents.assistantOpenChatClick);
    }

    const onHandleCloseChat = () => {
        setIsOpenChat(false);
        trackEvent(trackingEvents.assistantCloseChatClick);
    }

    const [query, setQuery] = useQueryParams({
        "conversation-uuid": '',
    });

    const handleDisableMenus = () => {
        setIsOpenResponsiveSidebar(false);
        setIsOpenResponsiveMenu(false);
    };

    return (
        <PanelOwnerWrap>
            <ContentWrap>
                <Header
                    isOpenResponsiveSidebar={isOpenResponsiveSidebar}
                    onOpenResponsiveSidebar={setIsOpenResponsiveSidebar}
                    isOpenResponsiveMenu={isOpenResponsiveMenu}
                    onOpenResponsiveMenu={setIsOpenResponsiveMenu}
                />
                <div className="content">
                    <Outlet />
                </div>
            </ContentWrap>

            <Sidebar
                isOpenResponsiveSidebar={isOpenResponsiveSidebar}
                onOpenChat={onHandleOpenChat}
            />

            {(isOpenResponsiveSidebar || isOpenResponsiveMenu) && (
                <button
                    type="button"
                    className="responsive-overlay"
                    onClick={() => handleDisableMenus()}
                    aria-label="Close sidebar"
                />
            )}

            <Drawer isOpen={isOpenChat} onOpen={() => onHandleCloseChat()}>
                <DrawerFormHeader title={t('assistantTitle')} />
                
                <Assistant uuid={query["conversation-uuid"] as string} setUuid={(uuid) => setQuery({ "conversation-uuid": uuid })} />
            </Drawer>
        </PanelOwnerWrap>
    );
};

export default PanelOwner;
