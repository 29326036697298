import styled from 'styled-components';

export const Core = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: ${props => props.theme.spacing[4]};

  padding: ${props => props.theme.spacing[4]};
  overflow-y: auto;

  max-height: calc(100vh - 12rem);
`;

export const InputWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 6.25rem;
  align-items: center;
  gap: ${props => props.theme.spacing[4]};

  padding: 0 ${props => props.theme.spacing[4]} ;
  width: 100%;

  > div {
    width: 100%;
  }

  button {
    height: 3.25rem;
  }
`; 

