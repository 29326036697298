import { useMutation, useQuery } from 'react-query';

import { QUERIES } from '@constants';
import { IAAssistantConversation, IAAssistantMessage } from '@types';

import api from '../api';


export const ASSISTANT_CONVERSATION_URL = '/assistant/conversations';
export const ASSISTANT_CONVERSATION_URL_UUID = (uuid: string) => `/assistant/conversations/${uuid}`;
export const ASSISTANT_CONVERSATION_MESSAGE_URL = (uuid: string ) =>  `/assistant/conversations/${uuid}/messages`;

export const useGetOwnerAssistantConversation = (uuid?: string) => {
    const getConversation = async () => {
        try {
            const { data } = await api.get(ASSISTANT_CONVERSATION_URL_UUID(uuid || ''));

            return {
                success: true,
                message: 'Conversa encontrada com sucesso!',
                data: data as IAAssistantConversation,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao buscar conversa!',
                data: {} as IAAssistantConversation,
            };
        }
    };

    return useQuery({
        queryKey: [QUERIES.OWNER.ASSISTANT_CONVERSATION.module, uuid],
        queryFn: getConversation,
        enabled: !!uuid,
    });
};

export const useCreateOwnerConversation = () => {
    const createConversation = async (): Promise<{
        success: boolean;
        message: string;
        data: IAAssistantConversation;
    }> => {
        try {
            const url = `${ASSISTANT_CONVERSATION_URL}`;

            const { data } = await api.post(url);

            return {
                success: true,
                message: 'Conversa criada com sucesso!',
                data: data as IAAssistantConversation,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao criar conversa!',
                data: {} as IAAssistantConversation,
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ASSISTANT_CONVERSATION.module],
        mutationFn: createConversation,
    });
};

export const useCreateOwnerConversationMessage = (conversationId: string) => {
    const createConversationMessage = async (payload: Partial<IAAssistantMessage>) => {
        try {
            const { data } = await api.post(ASSISTANT_CONVERSATION_MESSAGE_URL(conversationId), payload);

            return {
                success: true,
                message: 'Mensagem criada com sucesso!',
                data: data as IAAssistantMessage,
            };
        } catch (error) {
            return {
                success: false,
                message: 'Erro ao criar mensagem!',
                data: {} as IAAssistantMessage,
            };
        }
    };

    return useMutation({
        mutationKey: [QUERIES.OWNER.ASSISTANT_CONVERSATION_MESSAGE.module, conversationId],
        mutationFn: createConversationMessage,
    });
};
