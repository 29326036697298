import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, InputText } from 'components'

import { Core, Content, InputWrap } from './Chat.styles'
import { Message } from '../Message'


import type { ChatProps } from './Chat.type'


export const Chat = ({ messages, onSendMessage, isLoading }: ChatProps) => {
  const { t } = useTranslation()

  const [message, setMessage] = useState('')
  const contentRef = useRef<HTMLDivElement>(null)

  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    onSendMessage(message)

    setMessage('')
    
  }

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <Core>
      <Content ref={contentRef}>
        {messages?.map(({ author, content }, index) => (
          <Message
            author={author as 'assistant' | 'user'}
            content={content}
            isLoading={false}
            key={index}
          />
        ))}

        {isLoading && (
          <Message author="assistant" content="Loading..." isLoading />
        )}
      </Content>

      <form onSubmit={handleSendMessage}>
        <InputWrap>
          <InputText
            value={message}
            onChange={setMessage}
            placeholder={t('assistantInputPlaceholder')}
            name="message"
            autoComplete="off"
        />

        <Button type="submit">{t('assistantButtonSend')}</Button>
        </InputWrap>
      </form>
    </Core>
  )
}
