/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { marked } from 'marked';
import {Dot,Loading, MessageContainer, Markdown } from './Message.style'

import type { MessageProps } from './Message.type'


export const Message = ({ author, content, isLoading }: MessageProps) => {
  const [html, setHtml] = useState('');

  useEffect(() => {
    const parseMarkdown = async () => {
      const parsedHtml = await marked(content);
      setHtml(parsedHtml);
    };

    parseMarkdown();
  }, [content]);

  return (
    <MessageContainer author={author}>
      {isLoading ? (
        <Loading>
          <Dot />
          <Dot />
          <Dot />
        </Loading>
      ) : (
        <Markdown dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </MessageContainer>
  )
}
