import styled, { keyframes, css } from 'styled-components';

const bounceIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  60% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.625rem);
  }
`;

export const MessageContainer = styled.div<{ author: 'assistant' | 'user' }>`
  padding: ${props => props.theme.spacing[3]};
  border-radius: ${props => props.theme.borderRadius.default};
  max-width: 80%;
  animation: ${bounceIn} 0.5s ease;

  a {
    text-decoration: underline;
  }

  ${({ author }) =>
    author === 'assistant' &&
    css`
      background-color: ${props => props.theme.colors.gray200};
      border-bottom-left-radius: 0;
      margin-right: auto;

      a {
        color: ${props => props.theme.colors.primary600};
      }
    `}

  ${({ author }) =>
    author === 'user' &&
    css`
      background-color: ${props => props.theme.colors.active.primary};
      color: ${props => props.theme.colors.gray100};
      border-bottom-right-radius: 0;
      margin-left: auto;

      a {
        color: ${props => props.theme.colors.gray100};
      }
    `}
`;

export const Loading = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing[2]};
  font-style: italic;
  color: gray;
`;

export const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background-color: gray;
  border-radius: 50%;
  animation: ${bounce} 0.6s infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

export const Markdown = styled.div`

  strong {
    font-weight: 700;
  }

  a {
    text-decoration: underline;
  }

  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }

  h4 {
    font-size: 1rem;
    font-weight: 700;
  }

  h5 {
    font-size: 0.875rem;
    font-weight: 700;
  }

  h6 {
    font-size: 0.75rem;
    font-weight: 700;
  }

  p {
    margin: 0;
  }

  ul, ol {
    margin: 0.75rem 0;
    padding-left: 1.5rem;
  }

  ul {
    list-style: none;
    
    li {
      position: relative;
      
      &::before {
        content: "•";
        position: absolute;
        left: -1rem;
        color: ${props => props.theme.colors.gray500};
      }
    }
  }

  ol {
    list-style-position: outside;
    counter-reset: item;
    
    li {
      position: relative;
      counter-increment: item;
      
      &::before {
        content: counter(item) ".";
        position: absolute;
        left: -1.5rem;
        color: ${props => props.theme.colors.gray500};
      }
    }
  }

  li {
    margin-bottom: 0.5rem;
    line-height: 1.5;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    border-left: 2px solid ${props => props.theme.colors.gray300};
    padding-left: 1rem;
    margin-left: 1rem;
  }

  code {
    background-color: ${props => props.theme.colors.gray200};
    padding: 0.2rem 0.5rem;
    border-radius: ${props => props.theme.borderRadius.default};
  }

  pre {
    background-color: ${props => props.theme.colors.gray200};
    padding: 1rem;
    border-radius: ${props => props.theme.borderRadius.default};
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;
