export default {
    translation: {
        "routesAccountSelect": "Seleção de Conta",
        "routesAccountRemove": "Remover Conta",
        "routesDashboad": "Dashboard",
        "routesField": "Áreas de Terra",
        "routesActivity": "Atividades",
        "routesCrop": "Safras",
        "routesFinancial": "Financeiro",
        "routesSubscription": "Assinatura",

        "sidebarSelectAccount": "Seleção de Conta",

        "accountSelectedTitle": "Selecione uma conta",

        "accountRemoveTitle": "Remover Conta",
        "accountRemoveSubTitle": "Remover conta permanentemente",
        "accountRemoveDescription": "Ao remover a conta, todos os dados serão perdidos e não poderão ser recuperados. Tem certeza que deseja remover a conta?",
        "accountRemoveRequestRemove": "Solicitar Remoção",

        "activityTitle": "Atividades",
        "activityIconAltDescription": "Imagem de uma engranagem junto de alguns relógios",
        "activityListHeaderTitle": "Lista de atividades",
        "activityListHeaderSubTitle": "Todos os registros de atividades",
        "activityListButtonNewRegister": "Nova Atividade",
        "activityListColumnAreaWorker{{value}}{{unity}}": "Área Trabalhada {{value}} {{unity}}",
        "activityListColumnInfo": "Informações",
        "activityListColumnTime": "Início e fim",
        "activityListColumnStatus": "Status",
        "activityListColumnCreatedAt": "Criado em",
        "activityListColumnUpdatedAt": "Atualizado em",
        "activityListColumnActions": "Ações",

        "activityFormRequiredField": "Campo obrigatório",
        "activityFormButtonCancel": "Cancelar",
        "activityFormButtonSave": "Salvar",

        "activityFormNewTitle": "Nova Atividade",
        "activityFormEditTitle": "Editar Atividade",
        "activityFormOperationLabel": "Operação",
        "activityFormOperationPlaceholder": "Selecione a operação",
        "activityFormCropLabel": "Safra",
        "activityFormCropPlaceholder": "Selecione a safra",
        "activityFormDateInitialLabel": "Data Inicial",
        "activityFormDateInitialPlaceholder": "Selecione a data inicial",
        "activityFormDateEndLabel": "Data Final",
        "activityFormDateEndPlaceholder": "Selecione a data final",
        "activityFormAreaWorkedLabel": "Área Trabalhada",
        "activityFormAreaWorkedPlaceholder": "Informe a área trabalhada",
        "activityFormWarehouseLabel": "Local de estoque",
        "activityFormWarehousePlaceholder": "Selecione o local de estoque",
        "activityFormObservationLabel": "Observações",
        "activityFormObservationPlaceholder": "Informe as observações",

        "activityFormFieldFieldNew": "Adicionar Área de Terra",
        "activityFormFieldFieldEdit": "Editar Área de Terra",
        "activityFormFieldFieldLabel": "Áreas de Terra",
        "activityFormFieldFieldQuantityLabel": "Quantidade",
        "activityFormFieldFieldQuantityPlaceholder": "Informe a quantidade",
        "activityFormFieldFieldSelectLabel": "Áreas de Terra",
        "activityFormFieldFieldSelectPlaceholder": "Selecione a área de terra",
        "activityFormFieldFieldButtonDelete": "Remover",
        "activityFormFieldFieldButtonBack": "Cancelar",
        "activityFormFieldFieldButtonNext": "Avançar",

        "activityFormPatrimonyLabel": "Maquinários",
        "activityFormPatrimonyNew": "Adicionar Maquinário",
        "activityFormPatrimonyEdit": "Editar Maquinário",
        "activityFormPatrimonySelectLabel": "Maquinário",
        "activityFormPatrimonySelectPlaceholder": "Selecione o maquinário",
        "activityFormPatrimonyMarkerInitialLabel": "Marcador Inicial",
        "activityFormPatrimonyMarkerInitialPlaceholder": "Informe o marcador inicial",
        "activityFormPatrimonyMarkerEndLabel": "Marcador Final",
        "activityFormPatrimonyMarkerEndPlaceholder": "Informe o marcador final",
        "activityFormPatrimonyButtonDelete": "Remover",
        "activityFormPatrimonyButtonBack": "Cancelar",
        "activityFormPatrimonyButtonNext": "Avançar",

        "activityFormInputLabel": "Insumos",
        "activityFormInputNew": "Adicionar Insumo",
        "activityFormInputEdit": "Editar Insumo",
        "activityFormInputQuantityLabel": "Quantidade",
        "activityFormInputQuantityPlaceholder": "Informe a quantidade",
        "activityFormInputSelectLabel": "Insumo",
        "activityFormInputSelectPlaceholder": "Selecione o insumo",
        "activityFormInputButtonDelete": "Remover",
        "activityFormInputButtonBack": "Cancelar",
        "activityFormInputButtonNext": "Avançar",

        "activityShowTitle": "Atividade",
        "activityShowWarehouseLabel{{value}}": "Local de estoque: {{value}}",
        "activityShowCropLabel{{value}}": "Safra {{value}}",
        "activityShowTotalCostLabel": "Custo da atividade",
        "activityShowCostLabel{{unity}}": "Custo por {{unity}}",
        "activityShowCurrency{{value}}": "R$ {{value}}",
        "activityShowAreaTitle": "Áreas de Terra da Atividade",
        "activityShowFieldListIdentification": "Identificação",
        "activityShowFieldListValue": "Áreas",
        "activityShowAreaSize{{value}}{{unity}}": "{{value}} {{unity}}",
        "activityShowAreaWorked{{value}}{{unity}}": "Área trabalhada: {{value}} {{unity}}",
        "activityShowTimeLabel": "Data inicial e final:",
        "activityShowTimeValue{{dateInitial]}{{dateEnd}}": "De {{dateInitial}} até {{dateEnd}}",
        "activityShowAreaWorkerLabel": "Área trabalhada:",
        "activityShowAreaWorkerValue{{value}}{{unity}}": "{{value}} {{unity}}",
        "activityShowObservationLabel": "Observações:",
        "activityShowEmptyObservation": "Sem observações",
        "activityShowChangeStatusDescription": "Clique nos círculos acima para alterar a situação.",
        "activityShowSuccessUpdateStatus": "Status atualizado com sucesso!",
        "activityShowPatrimonyTitle": "Maquinários da Atividade",
        "activityShowPatrimonyListIdentification": "Identificação",
        "activityShowPatrimonyListValue": "Marcadores",
        "activityShowPatrimonyListMarker{{markerInitial}}{{markerCurrent}}": "{{markerInitial}} H - {{markerCurrent}} H",
        "activityShowInputTitle": "Insumos da Atividade",
        "activityShowInputListIdentification": "Identificação",
        "activityShowInputListValue": "Quantidades",
        "activityShowTransportTitle": "Transportes da Atividade",
        "activityShowTransportListIdentification": "Identificação",
        "activityShowTransportListValue": "Valor Líquido",
        "activityShowTransportListDescription{{ticket}}": "Ticket: {{ticket}}",
        "activityShowTransportListDescription{{date}}": "Data: {{date}}",

        "removeRegisterDefaultTitle": 'Você tem certeza?',
        "removeRegisterDefaultText": 'Se você confirmar este registro será removido e está ação não poderá ser revertida!',
        "removeRegisterDefaultConfirmButtonText": 'Sim, remover registro!',
        "removeRegisterDefaultCancelButtonText": 'Não, cliquei errado!',
        "removeRegisterSuccessMessageDefault": "Registro removido com sucesso!",

        "assistantTitle": "Assistente",
        "assistantSubTitle": "Pergunte o que você precisa",
        "assistantInputPlaceholder": "Digite sua mensagem",
        "assistantButtonSend": "Enviar",

        "dashboardChartsYearlyWeatherTitle": "Gráfico de chuvas",
        "dashboardChartsYearlyWeatherSubTitle": "Visão geral das chuvas anuais",
        "dashboardChartsYearlyWeatherRainLabel": "Precipitação de Chuva",
        "dashboardChartsYearlyWeatherTemperatureLabel": "Temperatura",
        "dashboardChartsYearlyWeatherHumidityLabel": "Umidade",
        "dashboardChartsYearlyWeatherAirPressureLabel": "Pressão do Ar",
        "dashboardChartsExpensesTitle": "Top 5 Despesas",
        "dashboardChartsExpensesSubTitle": "Visão geral das maiores despesas da propriedade",
        "dashboardChartsExpensesPlaceholder": "Categoria de Despesa",

        "cropTitle": "Safras",
        "cropListTitle": "Lista de Safras",

        "cropListHeaderTitle": "Lista de safras",
        "cropListHeaderSubTitle": "Todos os registros de safras",

        "cropListColumnInfo": "Informações",
        "cropListColumnTime": "Início e fim",
        "cropListColumnCoastType": "Tipo de Custo",
        "cropListColumnCreatedAt": "Criado em",
        "cropListColumnUpdatedAt": "Atualizado em",
        "cropListColumnActions": "Ações",

        "cropIconAltDescription": "Imagem de um pequeno calendário com trigos",
        "cropListColumnTotalSize{{value}}{{unity}}": "Área Total {{value}} {{unity}}",

        "cropListRemoveRegisterClick": "Remover Registro",
        "cropListRemoveRegisterConfirmClick": "Sim, remover registro!",
        "cropListRemoveSuccessTitle": "Registro removido com sucesso!",
        "cropListRemoveSuccessMessage": "O registro foi removido com sucesso!",
        "cropListRemoveSuccess": "Registro removido com sucesso!",
        "cropListRemoveErrorTitle": "Erro ao remover registro!",
        "cropListRemoveErrorMessage": "Houve um erro ao remover o registro, tente novamente!",
        "cropListRemoveError": "Erro ao remover registro!",

        "cropShowHeaderBalanceLabel": "Balanço da Safra",
        "cropShowHeaderBalanceExplication": "receita subtraída das despesas",
        "cropShowHeaderExpenseLabel": "Despesa: ",
        "cropShowHeaderIncomeLabel": "Receita: ",
        "cropShowCoastTypeLabel": "Tipo de Custo: ",
        "cropShowDatesLabel{{initial}}{{end}}": "De {{initial}} até {{end}}",
        "cropShowAreaWorkedLabel": "Área Trabalhada",
        "cropShowAreaWorkedValue{{value}}{{unity}}": "{{value}} {{unity}}",
        "cropShowBalancePerAreaLabel{{unity}}": "Balanço por {{unity}}",
        "cropShowExpensePerAreaLabel{{unity}}": "Despesa por {{unity}}",
        "cropShowIncomePerAreaLabel{{unity}}": "Receita por {{unity}}",
        "cropShowCultureLabel": "Cultura da safra: ",
        "cropShowWarehouseLabel": "Local de estoque: ",
        "cropShowWarehouseEmpty": "indefinido",

        "cropShowFieldTitle": "Áreas de Terra",
        "cropShowFieldName": "Identificação",
        "cropShowFieldQuantity": "Área",


        "financeTitle": "Finanças",
        "financeListHeaderIncomeExpected": "Receita Prevista",
        "financeListHeaderIncomeRealized": "Receita Realizada",
        "financeListHeaderExpenceExpected": "Despesa Prevista",
        "financeListHeaderExpenceRealized": "Despesa Realizada",
        "financeListHeaderExpence": "Despesas",
        "financeListHeaderIncome": "Receitas",
        "financeListHeaderInstallments": "Movimentações",
        "financeListHeaderCategories": "Categorias",
        "financeListHeaderPeople": "Pessoas",

        "financeListSidebarFilterTimeTitle": "Filtrar por tempo",
        "financeListSidebarFilterSelectPeriodTimeTitle": "Selecione o intervalo de tempo",
        "financeListSidebarFilterSelectPeriodTimeDay": "Dia",
        "financeListSidebarFilterSelectPeriodTimeWeek": "Semana",
        "financeListSidebarFilterSelectPeriodTimeMonth": "Mês",
        "financeListSidebarFilterSelectPeriodTimeYear": "Ano",
        "financeListSidebarFilterSelectPeriodTimeDisabled": "Sem Intervalo",
        "financeListSidebarFilterSelectPositionTimeTitle": "Selecione a posição do tempo",
        "financeListSidebarFilterSelectPositionTimePrevious": "Anterior",
        "financeListSidebarFilterSelectPositionTimeCurrent": "Atual",
        "financeListSidebarFilterSelectPositionTimeNext": "Seguinte",
        "financeListSidebarFilterCropTitle": "Filtrar por safra",
        "financeListSidebarFilterCropSubTitle": "Selecione a safra",

        "financeListHeaderExpenseTitle": "Lista de Despesas",
        "financeListHeaderExpenseSubTitle": "Todos os registros correspodentes ao filtro",
        "financeListButtonNewRegister": "Nova ",
        "financeListRegisterWithFile": "Registro com arquivo",
        "financeListColumnInfo": "Informações",
        "financeListColumnTime": "Início e fim",
        "financeListColumnStatus": "Status",
        "financeListColumnDateIssue": "Emissão",
        "financeListColumnValueNet": "Valor Líquido",
        "financeListColumnActions": "Ações",
        "financeIconAltDescription": "Imagem onde tem uma troca de papeis, o título representando um trator e no outro uma moeda",

        "financeFormWithFileTitle": "Registro com Arquivo",
        "financeFormWithFileDescription": "Faça o upload do XML da sua nota fiscal. Nosso sistema processará as informações e registrará sua despesa instantaneamente!",
        "financeFormTitleList": "Lista de Arquivos",
        "financeFormLabel": "Selecione um arquivo",
        "financeFormUpload": "Arraste e solte os arquivos aqui ou clique para adicionar",

        "financeShowTitle": "Finança",
        "financeShowHeaderDescription": "Valor Líquido da {{operation}}",
        "financeShowNoWarehouse": "Local de estoque indefinido",
        "financeShowHeaderDiscount": "Desconto: {{value}}",
        "financeShowHeaderFreight": "Frete: {{value}}",
        "financeShowIconAltDescription": "Imagem onde tem uma troca de papeis, o título representando um trator e no outro uma moeda",
        "financeShowLabelAppropriationOfCosts": "Apropriação de custos",
        "financeShowLabelObservation": "Observações",
        "financeShowObservationEmpty": "Sem observações",
        "financeShowChangeStatusDescription": "Clique nos círculos acima para alterar a situação.",
        "financeShowSuccessUpdateStatus": "Status atualizado com sucesso!",
        "financeShowInputTitle": "Insumos da {{operation}}",
        "financeShowInputName": "Identificação",
        "financeShowInputQuantity": "Quantidade",
        "financeShowInstallmentsTitle": "Parcelas da {{operation}}",
        "financeShowInstallmentsIdentifier": "Identificação",
        "financeShowInstallmentsIdentifierLabel": "Parcela nº {{number}}",
        "financeShowInstallmentsValue": "Valor",
        "financeShowCropTitle": "Rateio entre Safra da {{operation}}",
        "financeShowCropName": "Identificação",
        "financeShowCropValue": "Valores",
        "financeShowTransportGroupByCropTitle": "Produtos Finais da {{operation}}",
        "financeShowTransportGroupByCropName": "Identificação",
        "financeShowTransportGroupByCropValue": "Quantidades",
        "financeShowServiceTitle": "Serviços da {{operation}}",
        "financeShowServiceName": "Identificação",
        "financeShowServiceValue": "Valores",
        "financeShowButtonEdit": "Editar",

        "financeFormNewTitle": "Novo Registro",
        "financeFormEditTitle": "Editar Registro",
        "financeFormRequiredField": "Campo obrigatório",
        "financeFormButtonSave": "Salvar",
        "financeFormButtonCancel": "Cancelar",
        "financeFormDateLabel": "Data de Emissão",
        "financeFormDatePlaceholder": "Selecione a data de emissão",
        "financeFormNumberNFLabel": "Número da Nota Fiscal",
        "financeFormNumberNFPlaceholder": "Informe o número da nota fiscal",
        "financeFormCategoryLabel": "Categoria",
        "financeFormCategoryPlaceholder": "Selecione a categoria",
        "financeFormWarehouseLabel": "Local de Estoque",
        "financeFormWarehousePlaceholder": "Selecione o local de estoque",
        "financeFormPeopleLabel": "Comprando de",
        "financeFormPeoplePlaceholder": "Selecione a pessoa",
        "financeFormValueDiscountLabel": "Valor Desconto",
        "financeFormValueDiscountPlaceholder": "Informe o valor do desconto",
        "financeFormValueShippingLabel": "Valor Frete",
        "financeFormValueShippingPlaceholder": "Informe o valor do frete",
        "financeFormValueNetLabel": "Valor Líquido",
        "financeFormValueNetPlaceholder": "Informe o valor líquido",
        "financeFormFormOfPaymentLabel": "Forma de Pagamento",
        "financeFormFormOfPaymentPlaceholder": "Selecione a forma de pagamento",
        "financeFormInstallmentsLabel": "Parcelas",
        "financeFormInstallmentsPlaceholder": "Informe o número de parcelas",
        "financeFormNumberParcelsLabel": "Número de Parcelas",
        "financeFormNumberParcelsPlaceholder": "Informe o número de parcelas",
        "financeFormInputLabel": "Insumos",
        "financeFormInputNew": "Adicionar Insumo",
        "financeFormInputEdit": "Editar Insumo",
        "financeFormInputSelectLabel": "Insumo",
        "financeFormInputSelectPlaceholder": "Selecione o insumo",
        "financeFormInputQuantityLabel": "Quantidade",
        "financeFormInputQuantityPlaceholder": "Informe a quantidade",
        "financeFormInputAmountLabel": "Valor Total",
        "financeFormInputAmountPlaceholder": "Informe o valor total",
        "financeFormInputValueUnityLabel": "Valor da Unidade",
        "financeFormInputValueUnityPlaceholder": "Informe o valor da unidade",
        "financeFormInputButtonDelete": "Remover",
        "financeFormInputButtonBack": "Cancelar",
        "financeFormInputButtonNext": "Avançar",

        "financeFormServiceLabel": "Serviços",
        "financeFormServiceHourUnity": "Hora (s)",
        "financeFormServiceNew": "Adicionar Serviço",
        "financeFormServiceEdit": "Editar Serviço",
        "financeFormServiceSelectLabel": "Serviço",
        "financeFormServiceSelectPlaceholder": "Selecione o serviço",
        "financeFormServiceHoursLabel": "Horas",
        "financeFormServiceHoursPlaceholder": "Informe a quantidade de horas",
        "financeFormServiceAmountLabel": "Valor Total",
        "financeFormServiceAmountPlaceholder": "Informe o valor total",
        "financeFormServiceValueHourLabel": "Valor da Hora",
        "financeFormServiceValueHourPlaceholder": "Informe o valor da hora",
        "financeFormServiceButtonDelete": "Remover",
        "financeFormServiceButtonBack": "Cancelar",
        "financeFormServiceButtonNext": "Avançar",


        "financeFormServicePlaceholder": "Selecione os serviços",
        "financeFormAppropriationOfCostsLabel": "Apropriação de Custos",
        "financeFormAppropriationOfCostsPlaceholder": "Selecione a apropriação de custos",
        "financeFormAppropriationOfCostsExplication": "É a forma que o gasto será contabilizado. Se ele for 'Rateado entre Safras' você deverá selecionar a ou as safras e qual a porcentagem que cada uma receberá de valor. Se for 'Gerar Estoque', não será adicionado nem um custo de imediato, serão gerados estoques para serem utilizados e o valor então será aplicado conforme a utilização do estoque. 'Sem Apropriação' fará com o que o valor da finança não seja aplicado em safra alguma.",

        "financeFormCropLabel": "Rateio entre Safras",
        "financeFormCropNew": "Adicionar Safra",
        "financeFormCropEdit": "Editar Safra",
        "financeFormCropSelectLabel": "Safra",
        "financeFormCropSelectPlaceholder": "Selecione a safra",
        "financeFormCropPercentLabel": "Percentagem de Rateio",
        "financeFormCropPercentPlaceholder": "Informe a percentagem de rateio",
        "financeFormCropButtonDelete": "Remover",
        "financeFormCropButtonNext": "Avançar",
        "financeFormCropButtonBack": "Cancelar",

        "financeFormTransportGroupedByCropAddItemClick": "Adicionar Produto Final",
        "financeFormTransportGroupedByCropEditItemClick": "Editar Produto Final",
        "financeFormTransportGroupedByCropSaveItemClick": "Salvar Produto Final",
        "financeFormTransportGroupedByCropRemoveItemClick": "Remover Produto Final",
        "financeFormTransportGroupedByCropLabel": "Produtos Finais",
        "financeFormTransportGroupedByCropNew": "Adicionar Produto Final",
        "financeFormTransportGroupedByCropEdit": "Editar Produto Final",
        "financeFormTransportGroupedByCropSelectLabel": "Safras com produtos finais",
        "financeFormTransportGroupedByCropSelectPlaceholder": "Selecione a safra",
        "financeFormTransportGroupedByCropQuantitiesLabel": "Quantidade",
        "financeFormTransportGroupedByCropQuantitiesPlaceholder": "Informe a quantidade",
        "financeFormTransportGroupedByCropValueUnityLabel": "Valor por Unidade",
        "financeFormTransportGroupedByCropValueUnityPlaceholder": "Informe o valor",
        "financeFormTransportGroupedByCropAmountLabel": "Total",
        "financeFormTransportGroupedByCropAmountPlaceholder": "Informe o total",
        "financeFormTransportGroupedByCropButtonDelete": "Remover",
        "financeFormTransportGroupedByCropButtonBack": "Voltar",
        "financeFormTransportGroupedByCropButtonNext": "Avançar",

        "financeFormInstallmentLabel": "Parcelas de Pagamento",
        "financeFormInstallmentNew": "Adicionar Parcela",
        "financeFormInstallmentEdit": "Editar Parcela",
        "financeFormInstallmentValueLabel": "Valor da Parcela",
        "financeFormInstallmentValuePlaceholder": "Informe o valor da parcela",
        "financeFormInstallmentDueDateLabel": "Data de Vencimento",
        "financeFormInstallmentDueDatePlaceholder": "Informe a data de vencimento",
        "financeFormInstallmentButtonDelete": "Remover",
        "financeFormInstallmentButtonNext": "Avançar",
        "financeFormInstallmentButtonBack": "Cancelar",
        "financeFormInstallmentTitleLabel": "Parcela nº {{number}} - {{date}}",

        "subscriptionFormRequiredField": "Campo obrigatório",

        "financeFormObservationLabel": "Observações",
        "financeFormObservationPlaceholder": "Informe as observações",

        "subscriptionFormPlanSelectTitle": "Selecione um plano",
        "subscriptionFormPlanSelectDescription": "Escolha um plano para continuar",
        "subscriptionFormRegisterTitle": "Cadastro",
        "subscriptionFormRegisterDescription": "Preencha os dados para continuar",
        "subscriptionFormPaymentTitle": "Pagamento",
        "subscriptionFormPaymentDescription": "Escolha a forma de pagamento",
        "subscriptionFormConfirmationTitle": "Confirmação",
        "subscriptionFormConfirmationDescription": "Revise os dados e confirme",

        "subscriptionFormPlanSelectorTitle": "Selecione um plano",
        "subscriptionFormPlanSelectorSubtitle": "Se precisar de mais detalhes chame no whatsapp {{number}}",
        "subscriptionFormPlanSelectorCurrency": "R$",
        "subscriptionFormPlanSelectorCurrencyPerMonth": "/mês",
        "subscriptionFormPlanSelectorBenefitTitle": "O que tem no {{planName}}?",
        "subscriptionFormPlanSelectorBenefitEmptyTitle": "Selecione um plano para ver os benefícios",
        "subscriptionFormPlanSelectorButtonCancel": "Cancelar",
        "subscriptionFormPlanSelectorButtonNext": "Avançar",

        "subscriptionFormRegisterAlertDescription": "É importante que os dados informados sejam da pessoa que realizará o pagamento. Se for pagar com cartão, use os dados do titular do cartão. Isso garante a segurança da sua compra e ajuda a evitar fraudes.",
        "subscriptionFormRegisterName": "Nome",
        "subscriptionFormRegisterNamePlaceholder": "Informe o nome",
        "subscriptionFormRegisterEmail": "E-mail",
        "subscriptionFormRegisterEmailPlaceholder": "Informe o e-mail",
        "subscriptionFormRegisterPhone": "Telefone",
        "subscriptionFormRegisterPhonePlaceholder": "Informe o telefone",
        "subscriptionFormRegisterDocumentType": "Tipo de Documento",
        "subscriptionFormRegisterDocumentTypePlaceholder": "Selecione o tipo de documento",
        "subscriptionFormRegisterDocumentNumber": "Número do Documento",
        "subscriptionFormRegisterDocumentNumberPlaceholder": "Informe o número do documento",
        "subscriptionFormRegisterPostalCode": "CEP",
        "subscriptionFormRegisterPostalCodePlaceholder": "Informe o CEP",
        "subscriptionFormRegisterAddressNumber": "Número da Residência",
        "subscriptionFormRegisterAddressNumberPlaceholder": "Informe o número",

        "subscriptionFormRegisterNext": "Próximo",

        "subscriptionFormPaymentBoxTitle": "Selecione um pagamento",
        "subscriptionFormPaymentBoxSubtitle": "Se precisar de mais detalhes chame no whatsapp {{number}}",
        "subscriptionFormPaymentImageTitle": "Selecione um cartão ou adicione um novo",
        "subscriptionFormPaymentAddCardButton": "Adicionar Novo Cartão",
        "subscriptionFormPaymentButtonSubmitCard": "Cadastrar Cartão",
        "subscriptionFormPaymentButtonSubmit": "Avançar",
        "subscriptionFormRequiredCardField": "É necessário fornecer um cartão",
        "subscriptionFormPaymentAlert": "Para verificar a validade do seu cartão, uma cobrança simbólica de R$ 1,00 será realizada. Essa quantia será integralmente reembolsada em até 48 horas após a confirmação da validade do seu cartão. Esse processo garante a segurança das suas transações e a verificação necessária sem custos adicionais para você.",
        "subscriptionFormPaymentCardFormTitle": "Cartão de Crédito",
        "subscriptionFormPaymentCardFormSubTitle": "Preencha os dados do cartão",
        "subscriptionFormPaymentCardNumber": "Número do Cartão",
        "subscriptionFormPaymentCardNumberPlaceholder": "Informe o número do cartão",
        "subscriptionFormPaymentCardExpiry": "Data de Validade",
        "subscriptionFormPaymentCardExpiryPlaceholder": "MM/AA",
        "subscriptionFormPaymentCardCvc": "Código de Segurança",
        "subscriptionFormPaymentCardCvcPlaceholder": "CVC",
        "subscriptionFormPaymentCardHolderName": "Nome escrito no cartão",
        "subscriptionFormPaymentCardHolderNamePlaceholder": "Informe o nome",
        "subscriptionFormPaymentCardBackButton": "Voltar",
        "subscriptionFormPaymentCardSubmitButton": "Avançar",
        "subscriptionFormFinishingSelectedPlan": "Plano Selecionado",
        "subscriptionFormFinishingPaymentMethod": "Forma de Pagamento",
        "subscriptionFormFinishingTermsPrefix": "Li e concordo com os",
        "subscriptionFormFinishingTermsTermsOfUse": "Termos de Uso",
        "subscriptionFormFinishingTermsAnd": "e a",
        "subscriptionFormFinishingTermsPrivacyPolicy": "Política de Privacidade",
        "subscriptionFormFinishingTermsSuffix": "do Agtor.",
        "subscriptionFormFinishingTermsError": "É necessário aceitar os termos para continuar, para aceitar clique no quadrado cinza acima.",
        "subscriptionFormFinishingButton": "Finalizar Assinatura",

        "subscriptionShowStatusActive": "Ativo",
        "subscriptionShowStatusCanceled": "Cancelado",
        "subscriptionShowStatusPending": "Pendente",
        "subscriptionShowStatusExpired": "Expirado",
        "subscriptionShowCurrentPlanTitle": "Plano Ativo",
        "subscriptionShowCurrentPlanSubtitle": "Seu plano atual é o {{planName}}",
        "subscriptionShowPlanDetailsTitle": "Detalhes do Plano",
        "subscriptionShowSelectedPlanLabel": "Plano selecionado:",
        "subscriptionShowAmountLabel": "Valor:",
        "subscriptionShowMonthly": "Mês",
        "subscriptionShowPaymentDetailsTitle": "Detalhes do Pagamento",
        "subscriptionShowCardLabel": "Cartão",
        "subscriptionShowValidityLabel": "Validade",
        "subscriptionShowBoletoLabel": "Boleto",
        "subscriptionShowBoletoMessage": "Sua assinatura está sendo paga com boleto bancário.",
        "subscriptionShowSubscriptionDetailsTitle": "Detalhes da Assinatura",
        "subscriptionShowStartDateLabel": "Início:",
        "subscriptionShowStatusLabel": "Status:",
        "subscriptionShowNextBillingDateLabel": "Próxima cobrança:",
        "subscriptionShowEndDateLabel": "Fim:",
        "subscriptionShowSuccessImageAlt": "Plano sucesso",
        "subscriptionShowCancelSubscriptionButton": "Cancelar Assinatura",
        "subscriptionShowNewSubscriptionButton": "Nova Assinatura",
        "subscriptionShowCancelConfirmTitle": "Você tem certeza?",
        "subscriptionShowCancelConfirmMessage": "Se você confirmar esta assinatura será cancelada e está ação não poderá ser revertida!",
        "subscriptionShowCancelConfirmButtonDelete": "Sim, cancelar assinatura!",
        "subscriptionShowCancelConfirmButtonCancel": "Não, cliquei errado!",
        "subscriptionShowCancelSuccessTitle": "Assinatura cancelada com sucesso!",
        "subscriptionShowCancelSuccessMessage": "Sua assinatura foi cancelada com sucesso!",
        "subscriptionShowCancelErrorTitle": "Erro ao cancelar assinatura!",
        "subscriptionShowCancelErrorMessage": "Houve um erro ao cancelar sua assinatura, tente novamente!",
        "subscriptionShowCanceledTitle": "Sua Assinatura foi Cancelada",
        "subscriptionShowCanceledMessage": "Informamos que o cancelamento de sua assinatura foi processado com sucesso. Apesar disso, você ainda pode continuar utilizando todos os benefícios e serviços disponíveis até o término do seu plano atual, que encerra em {{date}}. Aproveite ao máximo até lá!",

        "ACTIVITY_OPERATION_ENUM_SOLID_PREPARATION": 'Preparação do Solo',
        "ACTIVITY_OPERATION_ENUM_SEEDING": 'Semeadura',
        "ACTIVITY_OPERATION_ENUM_PLANTING": 'Plantio',
        "ACTIVITY_OPERATION_ENUM_FERTILIZATION": 'Adubação',
        "ACTIVITY_OPERATION_ENUM_APPLICATION": 'Aplicação',
        "ACTIVITY_OPERATION_ENUM_HARVEST": 'Colheita',
        "ACTIVITY_OPERATION_ENUM_IRRIGATION": 'Irrigação',
        "ACTIVITY_OPERATION_ENUM_REPLANTING": 'Replantio',
        "ACTIVITY_OPERATION_ENUM_RESEEDING": 'Resemeadura',
        "ACTIVITY_OPERATION_ENUM_OTHERS": 'Outro',

        "ACTIVITY_STATUS_ENUM_STARTED": "Em andamento",
        "ACTIVITY_STATUS_ENUM_DONE": "Concluída",
        "ACTIVITY_STATUS_ENUM_SCHEDULED": "Planejamento",

        "INPUT_UNITY_LITERS": "Litros",
        "INPUT_UNITY_MILLILITERS": "Mililitros",
        "INPUT_UNITY_TONS": "Toneladas",
        "INPUT_UNITY_KILOGRAMS": "Quilogramas",
        "INPUT_UNITY_GRAMS": "Gramas",
        "INPUT_UNITY_FLASKS": "Frascos",
        "INPUT_UNITY_BOXES": "Caixas",
        "INPUT_UNITY_UNITYS": "Unidades",
        "INPUT_UNITY_OTHERS": "Outros",

        "INPUT_UNITY_ABV_LITERS": "L",
        "INPUT_UNITY_ABV_MILLILITERS": "Ml",
        "INPUT_UNITY_ABV_TONS": "Ton",
        "INPUT_UNITY_ABV_KILOGRAMS": "Kg",
        "INPUT_UNITY_ABV_GRAMS": "G",
        "INPUT_UNITY_ABV_FLASKS": "Fr",
        "INPUT_UNITY_ABV_BOXES": "Cx",
        "INPUT_UNITY_ABV_UNITYS": "Un",
        "INPUT_UNITY_ABV_OTHERS": "Outro",

        "CROPS_UNITY_TONNES": "Toneladas",
        "CROPS_UNITY_BAGS60": "Saca 60 Kgs",
        "CROPS_UNITY_BAGS50": "Saca 50 Kgs",
        "CROPS_UNITY_BAGS40": "Saca 40 Kgs",
        "CROPS_UNITY_BAGS30": "Saca 30 Kgs",
        "CROPS_UNITY_GRAMS": "Gramas",
        "CROPS_UNITY_KILOGRAMS": "Quilogramas",
        "CROPS_UNITY_MILLIGRAMS": "Miligramas",
        "CROPS_UNITY_BOXES40.8": "Caixas 40,8 Kgs",
        "CROPS_UNITY_UNITS": "Unidades",

        "CROPS_COAST_TYPE_ENUM_MANUAL": "Manual",
        "CROPS_COAST_TYPE_ENUM_AUTO": "Automático",

        "FINANCE_OPERATION_ENUM_EXPENSE": "Despesa",
        "FINANCE_OPERATION_ENUM_INCOME": "Receita",

        "FINANCE_STATUS_ENUM_CANCELLED": "Cancelada",
        "FINANCE_STATUS_ENUM_STARTED": "Iniciada",
        "FINANCE_STATUS_ENUM_READY": "Concluída",

        "FINANCE_FORM_OF_PAYMENT_ENUM_IN_SIGHT": "À vista",
        "FINANCE_FORM_OF_PAYMENT_ENUM_IN_INSTALLMENTS": "Parcelado",

        "FINANCE_APPROPRIATION_OF_COSTS_ENUM_CROPS_RATED": "Rateado entre Safras",
        "FINANCE_APPROPRIATION_OF_COSTS_ENUM_IN_STORAGE": "Gerar Estoques",
        "FINANCE_APPROPRIATION_OF_COSTS_ENUM_NATURAL": "Apropriação Natural",
        "FINANCE_APPROPRIATION_OF_COSTS_ENUM_NONE": "Sem Apropriação",

        "USER_ACCESS_TYPE_ENUM_OWNER": "Proprietário",
        "USER_ACCESS_TYPE_ENUM_OPERATOR": "Operador",

    }
}